import React, { useCallback } from 'react'
import { appendRequired } from 'app/reusable/utils'

import moment from 'moment-timezone'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { convertFormat } from './dateConvertion'
import { Button, Grid, Icon } from '@material-ui/core'

const TIME_ZONE = 'America/Sao_Paulo'

const DatePickerInput = ({
  name,
  value,
  field,
  onRawChange,
  testingClassName,
  allowClearing = true,
}) => {
  const pyFormat = field.input_formats?.[0]
  const inputFormat = pyFormat ? convertFormat(pyFormat) : 'YYYY-MM-DD'
  const formatValue = useCallback((rawValue) => {
    const momObj = moment.tz(rawValue, TIME_ZONE).hours(0).minutes(0).seconds(0)
    return momObj.toISOString()
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <div className={testingClassName}>
        <Grid container spacing={1}>
          <Grid item xs>
            <DatePicker
              variant="outlined"
              label={appendRequired(field.label, field.required)}
              value={value ? moment.tz(value, inputFormat, TIME_ZONE) : null}
              inputFormat="DD/MM/YYYY"
              timezone={TIME_ZONE}
              onChange={(value) =>
                onRawChange(name, value ? formatValue(value) : value)
              }
              PopperProps={{ className: 'fix-datepicker-scroll' }}
              slotProps={{
                field: (params) => ({
                  InputProps: {
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  },
                  variant: 'outlined',
                  size: 'small',
                  fullWidth: true,
                }),
              }}
              cancelText="Cancelar"
            />
          </Grid>
          {!field.required && allowClearing && value && (
            <Grid item>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => onRawChange(name, null)}
              >
                <Icon>clear</Icon>
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </LocalizationProvider>
  )
}

export default DatePickerInput
